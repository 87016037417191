import SelectBox from "components/select-box";
import useVideoPerformanceHistory from "./useVideoPerformanceHistory";
import Loader from "components/loader";
import { LineChart, Line, XAxis, CartesianGrid, ResponsiveContainer,YAxis } from 'recharts';
import moment from 'moment';
const VideoPerformanceHistory = () => {
    const {
        changeFilter,
        period,
        stats,
        loading,
        graphKey,
        setGraphKey
    } = useVideoPerformanceHistory();
    return (
        <>
            <div className="d-flex flex-col gap-2">
                <div className="d-flex items-center justify-between">
                    <h3>Video Performance Verlauf</h3>
                    <SelectBox
                        onChange={(value) => changeFilter(value)}
                        placeholder="Branche wählen"
                        orientation="row"
                        style={{ flexDirection: "row" }}
                        label="Zeitraum"
                        value={period}
                        className="w-max items-center gap-2"
                        options={[
                            { title: "7 Tage", value: "7-tage" },
                            { title: "1 Monat", value: "1-monat" },
                            { title: "3 Monat", value: "3-monat" },
                            { title: "6 Monat", value: "6-monat" },
                            { title: "1 Jahr", value: "1-jahr" },
                        ]}
                    />
                </div>
                <div className="video-performance-history card w-max-fit w-min-fill">
                    {
                        loading
                            ?
                            <Loader size={20} />
                            :
                            <>
                                <div className="d-flex items-center gap-2">
                                    <h3 className="graph-heading">Kennzahlen</h3>
                                    <SelectBox
                                        onChange={(value) => setGraphKey(value)}
                                        value={graphKey}
                                        className="w-max"
                                        options={[
                                            { title: "Impressionen", value: "impressions" },
                                            { title: "Likes", value: "likes" },
                                            { title: "Gespeichert", value: "saves" },
                                            { title: "Bewerbungen", value: "applyNowClicks" },
                                            { title: "Viewtime", value: "viewtime" },
                                            { title: "Profilaufrufe", value: "profileViews" },
                                            { title: "Bewerbungsseite", value: "applications" },
                                        ]}
                                    />
                                </div>
                                <ResponsiveContainer minWidth={600} width="100%" height={300}>
                                    <LineChart
                                        data={stats}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 0,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={(date) => moment(date).format('DD.MM.YY')} // Format date to DD.MM.YY
                                        />
                                        <YAxis dataKey={graphKey}/>
                                        <Line type="monotone" dataKey={graphKey} strokeWidth={2} stroke="#0073E5" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
export default VideoPerformanceHistory;

