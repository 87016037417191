import { useState, useEffect } from "react";
import { getAnalyticsData } from "services";
const useUserDemographics = () => {
    const [activeKey, setActiveKey] = useState("age");
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);
    useEffect(() => {
        getUserDemographics(activeKey);
    }, [])
    const getUserDemographics = (groupBy) => {
        setLoading(true);
        getAnalyticsData({ groupBy: [groupBy] }).then((result) => {
            setStats(result);
            setLoading(false);
        })
    }
    const changeActiveKey = (value) => {
        setActiveKey(value);
        getUserDemographics(value);
    }
    return {
        activeKey,
        changeActiveKey,
        loading,
        stats: activeKey === "age" ? summarizeAgeData(stats) : summarizeDistrictRecordCount(stats, activeKey === "district")
    }
}
export default useUserDemographics;


// const summarizeAgeData = (data) => {
//     const summary = data.reduce((acc, curr) => {
//         const ageKey = curr.age === null
//             ? 'Keine Altersangabe'
//             : curr.age < 18
//                 ? curr.age.toString() // Use actual age for under 18
//                 : '18+'; // Group 18 and older

//         // Initialize the age category if it doesn't exist
//         if (!acc[ageKey]) {
//             acc[ageKey] = { count: 0, totalImpressions: 0 };
//         }

//         acc[ageKey].count += 1; // Count records for each category
//         acc[ageKey].totalImpressions += curr.impressions || 0; // Sum impressions for each category

//         return acc;
//     }, {});

//     const totalCount = Object.values(summary).reduce((sum, { count }) => sum + count, 0);

//     const result = Object.entries(summary).map(([ageGroup, { count, totalImpressions }]) => ({
//         age: ageGroup,
//         count, // Number of records for the age group
//         totalImpressions, // Total impressions for the age group
//         percentage: totalCount ? ((count / totalCount) * 100).toFixed(0) : 0, // Calculate percentage of count
//         impressionsPercentage: totalImpressions ? ((totalImpressions / totalImpressions) * 100).toFixed(0) : 0 // Calculate percentage of total impressions
//     }));

//     // Sort the result, placing 'Keine Altersangabe' at the end
//     return result.sort((a, b) => {
//         if (a.age === 'Keine Altersangabe') return 1; // Move to end
//         if (b.age === 'Keine Altersangabe') return -1; // Keep others before
//         return 0; // Keep the order for other entries
//     });
// };

const summarizeAgeData = (data) => {
    const summary = data.reduce((acc, curr) => {
        const ageKey = curr.age === null
            ? 'Keine Altersangabe'
            : curr.age < 18
                ? curr.age.toString() // Use actual age for under 18
                : '18+'; // Group 18 and older

        // Initialize the age category if it doesn't exist
        if (!acc[ageKey]) {
            acc[ageKey] = { count: 0, totalImpressions: 0 };
        }

        acc[ageKey].count += 1; // Count records for each category
        acc[ageKey].totalImpressions += curr.impressions || 0; // Sum impressions for each category

        return acc;
    }, {});

    const totalPercentageOfAll = Object.values(summary).reduce((sum, { totalImpressions }) => sum + totalImpressions, 0); // Total impressions across all groups

    const result = Object.entries(summary).map(([ageGroup, { count, totalImpressions }]) => ({
        age: ageGroup,
        count, // Number of records for the age group
        totalImpressions, // Total impressions for the age group
        // Calculate percentage of total impressions relative to all impressions
        percentage: totalPercentageOfAll > 0 ? ((totalImpressions / totalPercentageOfAll) * 100).toFixed(1) : 0
    }));

    return result;
};

const summarizeDistrictRecordCount = (data, target) => {
    if (!target) return;

    const summary = data.reduce((acc, curr) => {
        const districtKey = curr.district || 'Kein Standort bekannt'; // Handle null/undefined districts
        const impressions = curr.impressions || 0; // Handle null/undefined impressions

        // Initialize the district entry if it doesn't exist
        if (!acc[districtKey]) {
            acc[districtKey] = { count: 0, totalImpressions: 0 };
        }

        acc[districtKey].count += 1; // Count records for each district
        acc[districtKey].totalImpressions += impressions; // Sum impressions for each district

        return acc;
    }, {});

    const totalRecords = Object.values(summary).reduce((sum, { count }) => sum + count, 0);
    const totalPercentageOfAll = Object.values(summary).reduce((sum, { totalImpressions }) => sum + totalImpressions, 0); // Renamed variable

    // Convert summary object to an array and sort by total impressions
    const sortedSummary = Object.entries(summary)
        .map(([district, { count, totalImpressions }]) => ({
            district: district === 'City ' ? "Kein Standort bekannt" : district, // Keep it as a string
            count,
            totalImpressions,
            percentage: totalRecords ? ((count / totalRecords) * 100).toFixed(0) : 0, // Calculate percentage of records
            impressionsPercentage: totalPercentageOfAll ? ((totalImpressions / totalPercentageOfAll) * 100).toFixed(1) : 0 // Updated variable for impressions percentage
        }))
        .sort((a, b) => b.totalImpressions - a.totalImpressions); // Sort by total impressions descending

    // Take the top three districts and combine the rest into "Andere Standorte"
    const topThree = sortedSummary.slice(0, 4);
    const others = sortedSummary.slice(3);

    const othersCount = others.reduce((sum, record) => sum + record.count, 0);
    const othersImpressions = others.reduce((sum, record) => sum + record.totalImpressions, 0);
    const othersPercentage = totalRecords ? ((othersCount / totalRecords) * 100).toFixed(0) : 0;
    const othersImpressionsPercentage = totalPercentageOfAll ? ((othersImpressions / totalPercentageOfAll) * 100).toFixed(1) : 0; // Updated variable for "Andere Standorte"

    const result = [
        ...topThree,
        {
            district: "Andere Standorte",
            count: othersCount,
            totalImpressions: othersImpressions,
            percentage: othersPercentage,
            impressionsPercentage: othersImpressionsPercentage // Calculate impressions percentage for "Andere Standorte"
        }
    ];

    return result;
};

// const summarizeDistrictRecordCount = (data, target) => {
//     if (!target) return;
//     const summary = data.reduce((acc, curr) => {
//         const districtKey = curr.district || 'Kein Standort bekannt'; // Handle null/undefined districts
//         const impressions = curr.impressions || 0; // Handle null/undefined impressions

//         // Initialize the district entry if it doesn't exist
//         if (!acc[districtKey]) {
//             acc[districtKey] = { count: 0, totalImpressions: 0 };
//         }

//         acc[districtKey].count += 1; // Count records for each district
//         acc[districtKey].totalImpressions += impressions; // Sum impressions for each district

//         return acc;
//     }, {});

//     const totalRecords = Object.values(summary).reduce((sum, { count }) => sum + count, 0);
//     const totalImpressions = Object.values(summary).reduce((sum, { totalImpressions }) => sum + totalImpressions, 0);

//     // Convert summary object to an array and sort by total impressions
//     const sortedSummary = Object.entries(summary)
//         .map(([district, { count, totalImpressions }]) => ({
//             district: district === 'City ' ? "Kein Standort bekannt" : district, // Keep it as a string
//             count,
//             totalImpressions,
//             percentage: totalRecords ? ((count / totalRecords) * 100).toFixed(0) : 0, // Calculate percentage of records
//             impressionsPercentage: totalImpressions ? ((totalImpressions / totalImpressions) * 100).toFixed(0) : 0 // Impressions percentage
//         }))
//         .sort((a, b) => b.totalImpressions - a.totalImpressions); // Sort by total impressions descending

//     // Take the top three districts and combine the rest into "Andere Standorte"
//     const topThree = sortedSummary.slice(0, 4);
//     const others = sortedSummary.slice(3);

//     const othersCount = others.reduce((sum, record) => sum + record.count, 0);
//     const othersImpressions = others.reduce((sum, record) => sum + record.totalImpressions, 0);
//     const othersPercentage = totalRecords ? ((othersCount / totalRecords) * 100).toFixed(0) : 0;
//     const othersImpressionsPercentage = totalImpressions ? ((othersImpressions / totalImpressions) * 100).toFixed(0) : 0;

//     const result = [
//         ...topThree,
//         {
//             district: "Andere Standorte",
//             count: othersCount,
//             totalImpressions: othersImpressions,
//             percentage: othersPercentage,
//             impressionsPercentage: othersImpressionsPercentage // Calculate impressions percentage for "Andere Standorte"
//         }
//     ];

//     return result;
// };