import Logo from "assets/img/logo.png";
import { HomeIcon, SettingsIcon } from "assets/img/icons";
import { AnalyticIcon } from "assets/icons";
import ChangePasswordModal from "components/change-password-modal";
import useSidebar from "./useSidebar";
import DeleteProfileModal from "components/delete-profile-modal";
const Sidebar = () => {
  const {
    changePassword,
    deleteProfile,
    activeRoute,
    changeRoute
  } = useSidebar();
  const { isOpen, toggleModal } = changePassword;
  const { isOpenDelete, toggleModalDelete } = deleteProfile;
  return (
    <>
      {isOpen ? (
        <ChangePasswordModal onClose={() => toggleModal(false)} />
      ) : null}
      {isOpenDelete ? (
        <DeleteProfileModal onClose={() => toggleModalDelete(false)} />
      ) : null}
      <div className="sidebar">
        <div className="sidebar-inner-container">
          <div className="d-flex flex-col gap-1">
            <div className="logo-container pb-5" onClick={() => changeRoute("/")}>
              <img alt="Logo" src={Logo} />
            </div>
            {
              sidebar_routes.map((route) => (
                <div
                  key={`Sidebar Routes ${route.title}`}
                  onClick={() => changeRoute(route.path)}
                  className={
                    activeRoute === route.path
                      ? "active-tab profile-route"
                      : "inactive-tab profile-route"
                  }
                >
                  {route.icon}
                  <p className="text-white size-3">{route.title}</p>
                </div>
              ))
            }
            <div
              className={"inactive-tab profile-route"}
              onClick={() =>
                window.open(
                  "https://www.surfjobs.at/hilfe",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <p className="text-white size-3">Hilfe</p>
            </div>
          </div>
          <div className="d-flex flex-col justify-center align-center gap-3 pb-5">
            <div className="d-flex flex-col">
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/impressum"
                target="_blank"
                rel="noreferrer"
              >
                IMPRESSUM
              </a>
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/datenschutz"
                target="_blank"
                rel="noreferrer"
              >
                DATENSCHUTZ
              </a>
              <a
                className="text-center size-2 text-white pb-1"
                href="https://www.surfjobs.at/nutzungsbedingungen"
                target="_blank"
                rel="noreferrer"
              >
                NUTZUNGSBEDINGUNGEN
              </a>
              <h4 className="small-heading text-center pt-2">
                SURFjobs FlexCo
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;



const sidebar_routes = [
  {
    title: "Start",
    path: "/",
    icon: <HomeIcon />
  },
  {
    title: "Einstellungen",
    path: "/settings",
    icon: <SettingsIcon />
  },
  {
    title: "Analysen",
    path: "/analytics",
    icon: <AnalyticIcon />
  }
]

