// import useAnalyticsView from "./useAnalyticsView";
import HighlightStats from "./highlight-stats";
import VideoPerformanceStats from "./video-performance-stats";
import UserDemographics from "./user-demographics";
import moment from "moment";
import 'moment/locale/de';
const AnalyticsView = () => {
    return (
        <>
            <div className="analytic-container flex-col">
                <div className="d-flex flex-col">
                    <h3>Performance Dashboard</h3>
                    <p>{moment().format("LL")}</p>
                </div>
                <h3>Demografische Daten der Nutzer</h3>
                <UserDemographics />
                <HighlightStats />
                <VideoPerformanceStats />
            </div>

        </>
    )
}
export default AnalyticsView;

