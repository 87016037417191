import "components/input-field/input-field.scss";
const SelectBox = (props) => {
  const {
    label,
    orientation,
    className,
    onChange,
    value,
    disabled,
    options,
    isError,
    infoMessage,
  } = props;
  const selectProps = { disabled };
  if (value !== "") {
    selectProps.value = value;
  }
  return (
    <>
      <div className={`input-field-container ${orientation} ${className}`}>
        {
          label
            ?
            <>
              <div className="input-with-icon flex-row">
                {label ? <label className="input-label">{label}</label> : null}
                {infoMessage !== "" ? (
                  <div className="icon-container">
                    <span className="info-icon">&#9432;</span>
                    <div className="tooltip-select">{infoMessage}</div>
                  </div>
                ) : null}
              </div>
            </>
            :
            null
        }
        <div className="select-container">
          <select
            {...selectProps}
            //   style={{ height: 31 }}
            onChange={(e) => onChange(e.target.value)}
            className="input-field"
          >
            <option disabled={true}>Branche wählen</option>
            {options.map((opt, index) => (
              <option
                key={`Select Box Option ${label} - ${index}`}
                value={opt.value}
              >
                {opt.title}
              </option>
            ))}
          </select>
          <div className="chevron-icon">
            <svg
              height="34.001px"
              id="Capa_1"
              style={{ enableBackground: "new 0 0 58 34.001" }}
              version="1.1"
              viewBox="0 0 58 34.001"
              width="58px"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path d="M56.418,9.817c-2.037,2.089-23.479,22.51-23.479,22.51c-1.088,1.115-2.514,1.674-3.939,1.674  c-1.428,0-2.854-0.559-3.939-1.674c0,0-21.441-20.421-23.48-22.51c-2.037-2.09-2.176-5.848,0-8.078c2.174-2.229,5.211-2.406,7.879,0  L29,20.474L48.539,1.739c2.67-2.406,5.705-2.229,7.879,0C58.596,3.97,58.457,7.728,56.418,9.817z" />

            </svg>
          </div>
        </div>

        {isError ? <p className="error-message">{isError}</p> : null}
      </div>
    </>
  );
};

SelectBox.defaultProps = {
  orientation: "column",
  label: null,
  onChange: () => { },
  disabled: false,
  className: "",
  value: "",
  containerClass: "",
  options: [],
  infoMessage: "",
};
export default SelectBox;
