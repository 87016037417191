import Loader from "components/loader";
import useVideoAnalytics from "./useVideoAnaltics";
import { EyeIcon, LikeIcon, BookmarkIcon, SendIcon, TimerIcon } from "assets/icons";
import SelectBox from "components/select-box";
import VideoPerformanceHistory from "./video-performance-history";
import moment from "moment";
import 'moment/locale/de';
import { formatValues } from "utils";
const VideoAnalyticsView = () => {
    const {
        jobName,
        loading,
        changeFilter,
        period,
        stats,
        calculatePercentageChange
    } = useVideoAnalytics();
    return (
        <>
            <div className="analytic-container flex-col">
                <div className="d-flex flex-col">
                    <h3>{jobName}</h3>
                    <p>{moment().format("LL")}</p>
                </div>
                <div className="d-flex items-center justify-between">
                    <h3>Video Performance</h3>
                    <SelectBox
                        onChange={(value) => changeFilter(value)}
                        placeholder="Branche wählen"
                        orientation="row"
                        style={{ flexDirection: "row" }}
                        label="Zeitraum"
                        value={period}
                        className="w-max items-center gap-2"
                        options={[
                            { title: "7 Tage", value: "7-tage" },
                            { title: "1 Monat", value: "1-monat" },
                            { title: "3 Monat", value: "3-monat" },
                            { title: "6 Monat", value: "6-monat" },
                            { title: "1 Jahr", value: "1-jahr" },
                        ]}
                    />
                </div>
                <div className="highlight-row">
                    {
                        highlights_enums.map((item, index) => (
                            <div key={`Highlight Tiles ${item.key} - ${index}`} className="card highlight-card">
                                {
                                    loading
                                        ?
                                        <Loader size={20} />
                                        :
                                        <>
                                            <div className="d-flex items-center gap-1">
                                                {item.icon}
                                                <p className="icon-text">{item.title}</p>
                                            </div>
                                            <p className="highlight-key">
                                                {
                                                    !Number.isInteger(stats.current[item.key])
                                                        ?
                                                        formatValues(stats.current[item.key].toFixed(2))
                                                        :
                                                        formatValues(stats.current[item.key])
                                                }
                                            </p>
                                            {
                                                item.showPercentage
                                                    ?
                                                    <>
                                                        <p className="desc">Veränderung letzten {period}: </p>
                                                        <p
                                                            style={{ color: calculatePercentageChange(stats.current[item.key], stats.previous[item.key]).color }} >
                                                            {calculatePercentageChange(stats.current[item.key], stats.previous[item.key]).percentage}
                                                        </p>
                                                    </>
                                                    :
                                                    <p className="desc">Die durschnittliche Viewtime in Sekunden.</p>
                                            }
                                        </>
                                }
                            </div>
                        ))
                    }
                </div>
                <VideoPerformanceHistory />
            </div>
        </>
    )
}
export default VideoAnalyticsView;


const highlights_enums = [
    {
        key: "impressions",
        title: "Impressionen",
        icon: <EyeIcon width={20} height={20} />,
        showPercentage: true,
    },
    {
        key: "likes",
        title: "Likes",
        icon: <LikeIcon width={20} height={20} />,
        showPercentage: true,
    },
    {
        key: "saves",
        title: "Gespeichert",
        icon: <BookmarkIcon width={20} height={20} />,
        showPercentage: true,
    },
    {
        key: "applications",
        title: "Bewerbungen",
        icon: <SendIcon width={20} height={20} />,
        showPercentage: true,
    },
    {
        key: "viewtime",
        title: "Viewtime",
        icon: <TimerIcon width={20} height={20} />,
        showPercentage: false,
    },
    {
        key: "profileViews",
        title: "Profilaufrufe",
        icon: <EyeIcon width={20} height={20} />,
        showPercentage: true,
    },
    {
        key: "applyNowClicks",
        title: "Bewerbungsseite",
        icon: <LikeIcon width={20} height={20} />,
        showPercentage: true,
    },
]