import { useRef, useState } from "react";
import Button from "components/button";
import { UploadIcon } from "assets/img/icons";
import { toBase64 } from "utils";
import ProgressBar from "react-bootstrap/ProgressBar";

const UploadButton = (props) => {
  const {
    className,
    label,
    onChange = () => {},
    isError,
    fileType,
    infoMessage,
    setFileSizeError = () => {},
    progress,
  } = props;
  const ref = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setFileName(file.name);

    const videoName = file.name;
    const videoNameSlice = videoName
      .slice(videoName.length - 3, videoName.length)
      .toLowerCase();
    const allowedExtensions = ["mp4", "mov", "mkv"];

    if (!allowedExtensions.includes(videoNameSlice) && fileType === "video") {
      setFileSizeError(
        "Bitte nur folgende Dateitypen verwenden: mp4, mov, mkv!"
      );
      return;
    } else {
      setFileSizeError(false);
    }

    if (file.size > 70 * 1024 * 1024) {
      setFileSizeError("Video zu groß. \n(max. 70 MB)");
      return;
    } else {
      setFileSizeError(false);
    }

    toBase64(file)
      .then((result) => {
        onChange(result);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={`input-field-container ${className}`}>
        <div className="input-with-icon felx-row">
          {label && <label className="input-label">{label}</label>}
          {infoMessage !== "" ? (
            <div className="icon-container">
              <span className="info-icon">&#9432;</span>
              <div className="tooltip display-linebreak">{infoMessage}</div>
            </div>
          ) : null}
        </div>
        <input
          onChange={handleChange}
          className="d-none"
          type="file"
          accept={fileType}
          ref={ref}
        />
        <div className="d-flex align-center gap-1">
          <Button
            onClick={() => ref.current.click()}
            type="upload"
            icon={<UploadIcon />}
            title="Hochladen"
          />
          {fileName && <label className="upload-label">{fileName}</label>}
        </div>
        {isError && <p className="error-message">{isError}</p>}
        {progress > 0 && (
          <div class="d-flex progress-background">
            <div
              class="progress"
              style={{ width: Math.round(progress + 5) + "%" }}
            >
              {progress > 0 && Math.round(progress) + "%"}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

UploadButton.defaultProps = {
  className: "",
  disabled: false,
  isError: false,
  infoMessage: "",
};

export default UploadButton;
