import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getAnalyticsData } from "services";
import { getDateRange, formatPercentage } from "utils";
const useVideoAnalytics = () => {
    let { videoId } = useParams();
    const location = useLocation();
    const [period, setPeriod] = useState("1-monat");
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({
        current: {
            applications: 0,
            applyNowClicks: 0,
            impressions: 0,
            likes: 0,
            profileViews: 0,
            saves: 0,
            viewtime: 0,
        },
        previous: {
            applications: 0,
            applyNowClicks: 0,
            impressions: 0,
            likes: 0,
            profileViews: 0,
            saves: 0,
            viewtime: 0,
        }
    });
    const changeFilter = (value) => {
        const finalValue = value === "Branche wählen" ? "1-monat" : value;
        setPeriod(finalValue);
        getAnalytics(finalValue);
    }
    useEffect(() => {
        getAnalytics(period);
    }, [])

    const getAnalytics = (period) => {
        setLoading(true);
        const { fromDate, toDate, previousFromDate } = getDateRange(period);

        // Call API for the current period
        getAnalyticsData({ fromDate, toDate, videoId })
            .then((currentResult) => {
                if (currentResult) {
                    setStats((prevStats) => ({ ...prevStats, current: { ...currentResult[0] } }));
                }
                // Call API for the previous period
                return getAnalyticsData({ fromDate: previousFromDate, toDate: fromDate, videoId });
            })
            .then((previousResult) => {
                if (previousResult && previousResult.length > 0) {
                    setStats((prevStats) => ({
                        ...prevStats,
                        previous: { ...previousResult[0] },
                    }));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching analytics:", error);
                setLoading(false);
            });
    };



    return {
        jobName: location.state?.jobName,
        loading,
        stats,
        period,
        changeFilter,
        calculatePercentageChange,
    }
}
export default useVideoAnalytics;




const calculatePercentageChange = (currentValue, previousValue) => {
    let status = "increased";
    let percentage;

    if (previousValue === 0 && currentValue === 0) {
        percentage = 0;
    } else {
        let tempPreviosValue = previousValue;
        let tempAddition = 0;

        if (previousValue === 0) {
            tempPreviosValue = 1; // Prevent division by zero
            tempAddition = 1; // Adjust currentValue for percentage calculation
        }

        percentage = Math.round((((currentValue + tempAddition - tempPreviosValue) / tempPreviosValue) * 100));

        if (currentValue < tempPreviosValue) status = "decreased";
    }

    // Format percentage
    const formattedPercentage = formatPercentage(percentage);

    const payload = {
        percentage: formattedPercentage,
        status,
        color: status === "increased" ? "green" : "red"
    };

    return payload;
};