import Button from "components/button";
import InputField from "components/input-field";
import UploadButton from "components/upload-button";
import MobilePreview from "components/mobile-preview";
import VideoCustomControls from "components/video-custom-controls";
import useInsightVideo from "./useInsightVIdeo";
import { useState } from "react";

const AddInsightVideoView = () => {
  const [fileSizeError, setFileSizeError] = useState(false);
  const {
    navigate,
    model,
    handleModelChange,
    handleSubmit,
    loading,
    videoDetails,
    handleEditVideo,
    errors,
    handleDeleteVideo,
    videoRef,
    isUploadButtonDisabled,
    isEditButtonDisabled,
    progress,
  } = useInsightVideo(fileSizeError);
  const { name } = model;
  return (
    <>
      <div className="inner-container">
        <MobilePreview activeVideoId={videoDetails?.videoId} />
        <div className="detail-container">
          <div className="general-information d-flex flex-col gap-2">
            <div className="d-flex justify-space-between gap-2 align-center">
              {videoDetails ? (
                <>
                  <h3>INSIGHT VIDEO BEARBEITEN</h3>
                  <Button
                    onClick={handleDeleteVideo}
                    type="outline"
                    title="&nbsp;LÖSCHEN&nbsp;"
                  />
                </>
              ) : (
                <h3>INSIGHT VIDEO HINZUFÜGEN</h3>
              )}
            </div>
            <InputField
              value={name}
              isError={errors?.name}
              onChange={(value) => handleModelChange("name", value)}
              placeholder="Neues Insight Video"
              label="Video Titel"
              infoMessage="Geben Sie einen Titel für Ihr Insight Video ein, der den Inhalt des Videos prägnant beschreibt und Jugendliche anspricht."
            />
            <UploadButton
              isError={errors?.videoDataBase64 || fileSizeError}
              onChange={(value) => handleModelChange("videoDataBase64", value)}
              label={videoDetails ? "Insight Video ersetzen" : "Insight Video"}
              fileType="video"
              setFileSizeError={setFileSizeError}
              infoMessage="Insight Videos sollen einen authentischen Einblick in Ihr Unternehmen und dessen Arbeitsalltag bieten. Auf Insight-Videos ist keine Bewerbung möglich."
              progress={progress}
            />
            {/* {loading && (
              <div>
                <progress value={progress} max="100" />
              </div>
            )} */}
            {videoDetails ? (
              <>
                <div className="video-container">
                  <video
                    ref={videoRef}
                    width={200}
                    controls={false}
                    autoPlay={false}
                    src={videoDetails.url}
                  />
                  <div className="video-details-container">
                    <p className="video-name">{videoDetails.jobName}</p>
                    <p className="video-company">@{videoDetails.userName}</p>
                  </div>
                  <VideoCustomControls refElem={videoRef} />
                </div>
              </>
            ) : null}
            <div className="d-flex gap-1 pb-4">
              {videoDetails ? (
                <Button
                  disabled={isEditButtonDisabled()}
                  loading={loading}
                  onClick={handleEditVideo}
                  title="UPDATE"
                />
              ) : (
                <Button
                  loading={loading}
                  disabled={isUploadButtonDisabled()}
                  onClick={handleSubmit}
                  title="SPEICHERN"
                />
              )}
              <Button
                onClick={() => navigate("/")}
                type="outline"
                title="BEENDEN"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddInsightVideoView;
