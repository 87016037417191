import { Routes, Route } from "react-router-dom";
import LoginView from "views/login";
import RegisterScreen from "views/register";
import DashboardView from "views/dashboard";
import ProfileView from "views/profile-view";
import AddInsightVideoView from "views/insight-video";
import AddJobView from "views/add-job";
import PasswordView from "views/forgot-password";
import PaymentsView from "views/payments";
import SettingsView from "views/settings-page";
import AnalyticsView from "views/analytics";
import VideoAnalyticsView from "views/analytics/video-analytics";
export const ApplicationRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardView />} />
        <Route path="/profile" element={<ProfileView />} />
        <Route path="/analytics" element={<AnalyticsView />} />
        <Route path="/analytics/:videoId" element={<VideoAnalyticsView />} />
        <Route path="/add-insight-video" element={<AddInsightVideoView />} />
        <Route path="/add-job" element={<AddJobView />} />
        <Route path="/payments" element={<PaymentsView />} />
        <Route path="/settings" element={<SettingsView />} />
      </Routes>
    </>
  );
};

export const AuthorizationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginView />} />
      <Route path="/forgot-password" element={<PasswordView />} />
      <Route path="/signup" element={<RegisterScreen />} />
    </Routes>
  );
};
