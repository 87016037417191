import axios from "./axios";
export const getAnalyticsData = (data) => {
  let result = axios
    .post(`/auth/v1/getStatistics`, data)
    .then((res) => {
      return res?.data ? res.data : res?.response?.data;
    })
    .catch(() => {
      return { status: "error", message: "Some problem occured" };
    });
  return result;
};