import { useState } from "react";
import { useNavigate } from "react-router-dom";
const useSidebar = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [activeRoute, setActiveRoute] = useState(window?.location?.pathname || "/");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalReset, setOpenModalReset] = useState(false);
  const handleLogOut = () => {
    delete localStorage.removeItem("session");
    window.open(window.location.origin, "_self");
  };


  const changeRoute = (path) => {
    setActiveRoute(path);
    navigate(path);
  }
  return {
    changePassword: { isOpen: openModal, toggleModal: setOpenModal },
    deleteProfile: {
      isOpenDelete: openModalDelete,
      toggleModalDelete: setOpenModalDelete,
    },
    resetPassword: {
      isOpenReset: openModalReset,
      toggleModalReset: setOpenModalReset,
    },
    handleLogOut,
    activeRoute,
    changeRoute
  };
};

export default useSidebar;
