import SelectBox from "components/select-box";
import useUserDemographics from "./useUserDemographics";
import Loader from "components/loader";
import {
  ComposedChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
const UserDemographics = () => {
  const { activeKey, changeActiveKey, loading, stats } = useUserDemographics();
  const maxCount = Math.max(...stats.map((stat) => stat.count));
  const calculatedBarSize = Math.max(15, Math.ceil(maxCount / 10));
  return (
    <>
      <div style={{ height: "max-content" }} className="card w-min-fit">
        <div className="d-flex items-center gap-2">
          <h3 className="graph-heading">Kennzahlen</h3>
          <SelectBox
            onChange={(value) => changeActiveKey(value)}
            placeholder="Branche wählen"
            value={activeKey}
            className="w-max"
            options={[
              { title: "Alter", value: "age" },
              { title: "Region", value: "district" },
            ]}
          />
        </div>
        {loading ? (
          <Loader size={20} />
        ) : (
          <ResponsiveContainer
            minWidth={400}
            minHeight={stats.length * (stats.length * 10)}
          >
            <ComposedChart
              layout="vertical"
              data={stats}
              margin={{
                top: 48,
                right: 48,
                bottom: 8,
                left: 8,
              }}
            >
              {activeKey === "age" ? (
                <>
                  <XAxis type="number" hide />
                  <Bar
                    dataKey="totalImpressions"
                    barSize={calculatedBarSize}
                    fill="#0073E5"
                  >
                    <LabelList
                      dataKey="age"
                      position="top"
                      content={(props) =>
                        renderCustomizedLabel(props, activeKey)
                      }
                    />
                    <LabelList
                      dataKey="percentage"
                      position="right"
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>
                </>
              ) : (
                <>
                  <XAxis type="number" hide />
                  <Bar dataKey="totalImpressions" barSize={15} fill="#0073E5">
                    <LabelList
                      dataKey="district"
                      position="top"
                      content={(props) =>
                        renderCustomizedLabel(props, activeKey)
                      }
                    />
                    <LabelList
                      dataKey="impressionsPercentage"
                      position="right"
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};
export default UserDemographics;

const renderCustomizedLabel = (props, activeKey) => {
  const { x, y, value } = props; // 'value' should still reference 'age'
  return (
    <text
      x={x}
      fontSize={13}
      y={y - 8} // Adjust this value to position the text above the bar
      fill="#000" // You can change this color as needed
      textAnchor="start"
      dominantBaseline="start"
    >
      {activeKey === "age" && value !== "Keine Altersangabe"
        ? `${value} Jahre`
        : value}
    </text>
  );
};
