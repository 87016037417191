import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAnalyticsData } from "services";
import { getDateRange } from "utils";

const useVideoPerformanceHistory = () => {
    let { videoId } = useParams();
    const [period, setPeriod] = useState("1-monat");
    const [loading, setLoading] = useState(true);
    const [graphKey, setGraphKey] = useState("impressions");
    const [stats, setStats] = useState([]);
    const changeFilter = (value) => {
        setPeriod(value);
        getAnalytics(value);
    }
    useEffect(() => {
        getAnalytics(period);
    }, [])
    const getAnalytics = (period) => {
        setLoading(true);
        const { fromDate, toDate } = getDateRange(period);
        // Call API for the current period
        getAnalyticsData({ fromDate, toDate, videoId, groupBy: ["date"] })
            .then((currentResult) => {
                if (currentResult) {
                    setStats(currentResult);
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
    };
    return {
        changeFilter,
        loading,
        stats,
        graphKey,
        setGraphKey,
        period
    }
}
export default useVideoPerformanceHistory;

