import SelectBox from "components/select-box";
import { EyeIcon, LikeIcon, BookmarkIcon, SendIcon, TimerIcon } from "assets/icons";
import Loader from "components/loader";
import useVideoPerformanceStats from "./useVideoPerformanceStats";
import { formatValues } from "utils";
const VideoPerformanceStats = () => {
    const {
        changeFilter,
        period,
        stats,
        loading,
        calculatePercentageChange,
        navigate
    } = useVideoPerformanceStats();
    const { current } = stats
    return (
        <>
            <div className="d-flex flex-col gap-2">
                <div className="d-flex items-center justify-between">
                    <h3>Highlights aller Videos</h3>
                    <SelectBox
                        onChange={(value) => changeFilter(value)}
                        placeholder="Branche wählen"
                        orientation="row"
                        style={{ flexDirection: "row" }}
                        label="Zeitraum"
                        value={period}
                        className="w-max items-center gap-2"
                        options={[
                            { title: "7 Tage", value: "7-tage" },
                            { title: "1 Monat", value: "1-monat" },
                            { title: "3 Monat", value: "3-monat" },
                            { title: "6 Monat", value: "6-monat" },
                            { title: "1 Jahr", value: "1-jahr" },
                        ]}
                    />
                </div>
                <div className="video-performance-row">
                    {
                        loading
                            ?
                            <Loader size={20} />
                            :
                            <>
                                {
                                    current && current.map((item, index) => (
                                        <div key={`Current Video Stats ${item.videoId}`} className="card video-performance-tile">
                                            <div className="video-cover">
                                                <img alt={item.jobName} src={item.thumbnail} />
                                            </div>
                                            <div className="video-details-container">
                                                <div className="d-flex justify-between w-per-100">
                                                    <p className="icon-text">Job Video</p>
                                                    <p
                                                        onClick={() => navigate(`/analytics/${item.videoId}`, { state: { jobName: item.jobName } })}
                                                        className="icon-text link">
                                                        mehr Anzeigen
                                                    </p>
                                                </div>
                                                <h4 className="video-performance-title">{item.jobName}</h4>
                                                <div className="d-flex flex-wrap gap-4">
                                                    <div className="d-flex flex-col gap-1">
                                                        <div className="d-flex items-center gap-1">
                                                            <EyeIcon width={22} height={22} />
                                                            <p className="icon-text">Impression</p>
                                                        </div>
                                                        <p className="highlight-key">{formatValues(item.impressions)}</p>
                                                        <p className="desc">Veränderung letzten {period}: </p>
                                                        <p
                                                            style={{ color: calculatePercentageChange("impressions", item.videoId).color }} >
                                                            {calculatePercentageChange("impressions", item.videoId).percentage}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-col gap-1">
                                                        <div className="d-flex items-center gap-1">
                                                            <LikeIcon width={22} height={22} />
                                                            <p className="icon-text">Likes</p>
                                                        </div>
                                                        <p className="highlight-key">{formatValues(item.likes)}</p>
                                                        <p className="desc">Veränderung letzten {period}: </p>
                                                        <p
                                                            style={{ color: calculatePercentageChange("likes", item.videoId).color }} >
                                                            {calculatePercentageChange("likes", item.videoId).percentage}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-col gap-1">
                                                        <div className="d-flex items-center gap-1">
                                                            <BookmarkIcon width={22} height={22} />
                                                            <p className="icon-text">Gespeichert</p>
                                                        </div>
                                                        <p className="highlight-key">{formatValues(item.saves)}</p>
                                                        <p className="desc">Veränderung letzten {period}: </p>
                                                        <p
                                                            style={{ color: calculatePercentageChange("saves", item.videoId).color }} >
                                                            {calculatePercentageChange("saves", item.videoId).percentage}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-col gap-1">
                                                        <div className="d-flex items-center gap-1">
                                                            <SendIcon width={22} height={22} />
                                                            <p className="icon-text">Bewerbungen</p>
                                                        </div>
                                                        <p className="highlight-key">{formatValues(item.applyNowClicks)}</p>
                                                        <p className="desc">Veränderung letzten {period}: </p>
                                                        <p
                                                            style={{ color: calculatePercentageChange("applyNowClicks", item.videoId).color }} >
                                                            {calculatePercentageChange("applyNowClicks", item.videoId).percentage}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-col gap-1">
                                                        <div className="d-flex items-center gap-1">
                                                            <TimerIcon width={22} height={22} />
                                                            <p className="icon-text">Viewtime</p>
                                                        </div>
                                                        <p className="highlight-key">{formatValues(item?.viewtime?.toFixed(2))}</p>
                                                        <p className="desc">Die durschnittliche Viewtime in Sekunden. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                    }
                </div>
            </div>
        </>
    )
}
export default VideoPerformanceStats;